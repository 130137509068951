---
title: "Privacy Policy"
description: "Privacy Policy"
created: 2025-01-01
updated: 2025-01-01
footer_region: Compliance
rank: 1
show_toc: true
toc_depth: 2
---

# Privacy Policy

**Last updated:** December 10, 2024

When you use CentralCI, you are trusting us to handle your data with care. We take that trust *very* seriously.
This Privacy Policy describes how we collect, use, and protect your personal information.

When we refer to our Services in this Privacy Policy, we are referring to all of our products and services,
including our Platform, websites, and software.

## Privacy Guide
We understand that privacy policies can be complex. If you are looking for a more digestible breakdown of our
practices and procedures, we invite you to check out our [Privacy Guide](privacy-guide).
While we do recommend you read this policy, our Privacy Guide offers a straightforward overview, 
designed to help you quickly grasp our approach to your data. This guide is part of our commitment to 
clarity and transparency, ensuring you always feel informed and in control.

For the purposes of this Privacy Policy, “Personal Data” means any information relating to an identified or
identifiable individual. If you do not agree with this Privacy Policy, you should not use the Services to which this
Privacy Policy applies.

Terms not otherwise defined in this Privacy Policy will have the meaning as set forth in our 
[Terms of Service](terms-of-service) or any applicable Master Service Agreement (MSA) which we have 
entered into with you (“the Agreement”).

If you have any questions about this Privacy Policy please contact us
at [privacy@centralci.com](mailto:privacy@centralci.com).

## 1. What Information We Collect and Process

### a. Information You Provide to CentralCI
When you interact with the CentralCI Website, Platform, CentralCI Products or Services, marketplaces 
that sell CentralCI’s Products and any other sites or services that link to this Privacy Policy, we may 
collect Personal Data and other information from you, as further described below.

#### When You Visit Our Websites
We collect Personal Data from you when you submit web forms or interact with our websites; for example when subscribing
to a CentralCI newsletter, signing up for a webinar, subscribing to our Sub-processor notifications, one of our
services, or requesting customer support. We may ask for your email address, first and last name, job title, and other
similar business information. You are free to explore some of our websites without providing any Personal Data about
yourself.

#### Information we collect automatically
Even if you do not provide information to us, we automatically collect technical data, such as web logs about your use
of and interaction with our Services. We use the technical data to troubleshoot problems, gather information for
analysis of the Services, customize your experience when accessing our Services, and for other business purposes.

#### Event Information
When you register for a CentralCI event, we collect information such as name, address, phone number, and email address.
We use this information to communicate with you and, in some cases, facilitate your registration. Additionally, we may
share your registration data with service providers to provide event information and improve your experience for future
CentralCI events.

#### Account and User Information
We collect Personal Data when you sign up for a CentralCI Account, create or modify user information, set preferences,
or provide any other related information to access or utilize the Service.

#### Payment Information
We collect payment and billing information when you register for paid Services. For example, we may ask you to provide a
billing address or a billing contact for your CentralCI Account. If you give us payment information, we use it solely as
authorized by you in accordance with this Privacy Policy.

You may also provide payment information, such as a credit card number or bank account number, when purchasing products
or services. We use secure third-party payment service providers to manage payment processing, which is collected
through a secure payment process.

#### Customer Testimonials
We post customer testimonials and comments on our Website, which may contain Personal Data. We obtain each Customer’s
consent via email or through other agreements between Customers and CentralCI prior to posting the Customer’s name and
testimonial.

### b. Information We Process on Behalf of Customers When they Use the Services
For the purposes of this Privacy Policy, “Customer Data” means the Content, the Customer Personal Data, and any other
information that the Customer and/or its Authorized Users Transfers to CentralCI in connection with the use of the
Services.

Customer Personal Data means Personal Data that CentralCI processes as a data processor for the Customer for the purpose
of providing the Services. The Customer Personal Data includes Personal Data that the Customer and/or any Authorized
User Transfers through the Platform in connection with its use of the Services.

We want to make it clear that we do not control any Customer Data that is uploaded and stored on the Platform.

We store Customer Data on our service provider’s servers, the region determined by our Customers. We process Customer
Data as a processor under our Customer’s instructions and in compliance with the applicable Agreement.

Our Customers control and are responsible for correcting, deleting, or updating any Customer Data that they Transfer to
the Platform and are also responsible for complying with any regulations or laws that require providing notice,
disclosure, and/or obtaining consent prior to transferring the Personal Data to CentralCI for processing purposes.

Our Agreement prohibits the transfer or upload of Sensitive Information. You shall be solely liable for any and all
liabilities arising from or relating to the transfer of Sensitive Information.

### c. Information We Collect and Process When You Use the Platform

#### Information From Users With Accounts
If you create an Account, we require some basic information at the time of Account creation. To create an Account, you
have the option to either provide your name, password, and a valid email address or create an Account by logging into a
Hasura, Github, Google Account, or any other method listed on our sign-up page. You also have the option to give us more
information if you want to, which may include “User Personal Information.”

“User Personal Information” is any information about a user that could, alone or together with other information,
personally identify the user. Information such as a user name and password, an email address, a real name, and a profile
picture or an avatar are examples of “User Personal Information.”

User Personal Information does not include aggregated, non-personally identifying information. We may use aggregated,
non-personally identifying information to operate, improve, and optimize our Service.

We need your User Personal Information to create your Account and to provide the Service you use. We use your User
Personal Information, specifically your user name and email address, to identify you. We use your email address to
communicate with you as outlined in the email communication section below. We limit our use of your User Personal
Information to the purposes listed in this Privacy Policy.

#### Usage Data
We may generate and use technical logs, data and learnings about your use of the Platform, and Customer Data in an
aggregate, anonymized form (“Usage Data”).

We engage third-party providers to collect Usage Data. For more information about how we protect your information with
these service providers, please see the section “How We Share Personal Data”.

#### Third-Party Integrations
You may connect third-party integrations to your CentralCI Account. These third-party integrations may ask for certain
permissions to access Customer Data, Personal Data, or send information to your CentralCI Account. It is your
responsibility to review any third-party integrations you authorize. We may collect information about what types of
integrations you use in your CentralCI Account.

Any permission(s) granted by you grant(s) these third parties access to your Customer Data and your Personal Data, which
may include (but is not limited to) granting third-party applications access to view, store, and modify your Customer
Data, Personal Data, or any other data stored in your CentralCI Account. We are not responsible for the practices of
third-party integrations, so please carefully review the permissions you grant to third-party applications.

#### Information From Website Browsers
We use common internet technologies, such as web server logs, to collect data about all visitors that access our
Website.

The information we collect about visitors to our Website includes the visitor’s browser type, language preference,
referring site, additional websites requested, and the date and time of each visitor request. We also collect
potentially personally-identifying information like Internet Protocol (IP) addresses. We collect this information to
better understand how visitors to our Website use CentralCI, and to monitor and protect the security of the Website.

### d. Information We Collect From Other Sources

#### CentralCI Partners
We may receive information about you or other users of your CentralCI Account from our global network of partners. We
may receive this data from our deal and lead registration process through our partners.

We may also collect information about you through our partner co-marketing partnerships. For example, you may sign up to
a webinar or download a white paper published by CentralCI and a CentralCI co-marketing partner.

#### Market Places

When you purchase CentralCI Products through a third-party marketplace, we obtain certain Personal Data with the
marketplace provider to facilitate order processing, delivery, customer support, and to meet legal obligations. This
data includes your name, contact information, payment details, and order history.

#### Third Parties
We may receive information from third-party service providers, from related companies, and from our business and
solution partners.

#### Personal Data from Different Sources
We may combine Personal Data we collect from you with personal information we receive from other sources, such as
third-party providers of business information and publicly available sources (like social media platforms). This may
include physical mail addresses, job titles, email addresses, phone numbers, IP addresses, and social media profiles.
This helps us to update and improve our records, identify new customers, create more personalized advertising, suggest
products and services that may interest you, deliver personalized communications, and promote events. The collection of
your personal information by these other third-party providers is governed by the provider’s privacy policy.

#### Buttons, tools from other companies
Our Website may include features and widgets (such as the share and/or “like” button or interactive mini-programs) that
run on our Websites. These features may collect your IP address, which pages you visit on our sites. These features and
widgets are either hosted by a third-party or hosted directly on our Websites. This Privacy Policy does not apply to
these features. Your interactions with these features are governed by the privacy policy and other policies of the
companies providing them.

## 2. How We Use Personal Data
### To facilitate contractual and pre-contractual business relationships

We use Personal Data to enter into business relationships with prospective customers and to perform the contractual
obligations under the Agreements that we have with existing Customers. For example, we may collect your Personal Data
to schedule calls and meetings for the Platform’s demo, prepare offers for you, set up and manage your Account on the
Platform, or perform certain accounting, auditing, and billing activities.

### To comply with our regulatory and other legal obligations
These regulatory and other legal obligations include Data Processing requirements of the GDPR and “Anti-Money
Laundering” (AML) and “Know-Your-Customer” (KYC) obligations.

### To Communicate with You About the Services
We use the Account information you provide to CentralCI when signing up for an Account to send you transactional emails
about billing, account management, and other administrative matters. We may also send you updates regarding our
Agreement or other legal agreements, and may also communicate with you about security incidents via email.

### To Provide the Service and offer support
We use your Account information, Personal Data, and Customer Data to provide the Services. For example, we use the email
address you provide when signing up for the product to create your Account, and we use your payment information to
process payments for paid use of the Platform or Products. We may also use this information to authenticate you when you
log in and to provide customer support.

We use your information both Personal Data and Customer Data to provide customer support, such as resolving technical
issues you encounter and analyzing product outages or bugs. You grant CentralCI the right to use your Personal Data and
Customer Data solely as necessary to (a) maintain, improve, protect and update the Services and the Platform; (b) to
prevent or address service, security, support, or technical issues related to the Services.

### To Personalize the Platform for you by understanding your needs.
We may use Usage Data to operate, improve, analyze and support the Platform and/or the Services, and for other lawful
business purposes. CentralCI might use the Customer Data without de-identification, aggregation and anonymization only
for troubleshooting and/or support purposes.

### To Develop new Services and Improve existing Services
We also collect Usage Data to develop and improve our Services and create new services and products. For example, we use
Usage Data to identify friction and bottlenecks in the user’s journey to optimize and improve the experience of our
customers. We also use Usage Data to assess trends and usage across the Platform to help us determine what new features
or integrations our users may be interested in.

### For Research and Analysis
We conduct aggregate analysis, market research and planning, develop business intelligence, generate statistical studies
that enable us to operate, protect, make informed decisions, and report on the performance of our business.

### To Secure and Protect our Platform and Users
We use your Account information to investigate and help prevent security incidents. We may also use this information to
meet legal requirements. We use your information to verify user Accounts and to detect and prevent abuse.

We use log files to provide general statistics regarding use of our Website by you, including how you use our websites,
what country you are logging in from (for analytics, export control, and regulatory purposes) and to help improve the
navigation experience. Your IP addresses are also collected and logged for security purposes; for example, to track
access patterns and investigate security events and incidents. For these purposes, we link this automatically-collected
data to other personal data provided by you, for example your name, email address, address, and phone number.

### To Market and Promote the Services
We use the information you provide to CentralCI to market and promote the Services and other offerings. For example, we
might use your email or physical address to send you information or content that we think may be of interest to you, by
post, email, or other means. We may also send you marketing communications relating to our business.

You may opt out of receiving this promotional content by following the instructions contained in each communication that
we send you or by contacting us at [privacy@centralci.com](mailto:privacy@centralci.com). 
If you unsubscribe from our marketing lists, we will only continue to contact you regarding administrative matters 
and to respond to your requests.

We may also reach out to you via email or telephone to ask about other CentralCI Products or Services you may be
interested in. If you have a call scheduled with a CentralCI representative, we may record and transcribe the call. You
will be notified prior to the call that the call is being recorded, and you will be offered an opportunity to opt out of
having the call recorded.

Where required by law, we will only send marketing communications with your consent. Otherwise, we will market and
advertise our products and services on the basis of our legitimate business interests.

We receive information from third-party service providers and other sources such as third-party providers of business
information and publicly available sources. We may receive and use information from these service providers and other
sources in combination with other information we collect through other means described in this Privacy Policy for the
purposes of providing you with updates and an improved service, like personalized communications, event promotion, and
tailoring the websites or product to improve user experience.

### Other purposes if we obtain your consent
We share your data with third parties when you give us consent to do so.

### Legal Basis for Processing Personal Data (EEA and UK visitors only)
Our legal basis for collecting and using the Personal Data described above will depend on the Personal Data concerned
and the specific context in which we collect it. However, we will normally collect Personal Data from you only where we
have your consent to do so, where we need the Personal Data to perform a contract with you, or where the processing is
in our legitimate interests and not overridden by your data protection interests or fundamental rights and freedoms. In
some cases, we may also have a legal obligation to collect Personal Data from you.

If we ask you to provide Personal Data to comply with a legal requirement or to perform a contract with you, we will
make this clear at the relevant time and advise you whether the provision of your Personal Data is mandatory or not (as
well as of the possible consequences if you do not provide your Personal Data). Similarly, if we collect and use your
Personal Data in reliance on our legitimate interests (or those of any third party), we will make clear to you at the
relevant time what those legitimate interests are.

## 3. How We Share Personal Data
### a. Service Providers

We may share Personal Data with our third-party service providers to support our Services. For example, we use
service providers for data hosting, application development, marketing, sales support, and customer support. We may
need to share your information with service providers to provide information about products or services to you.
Examples may include removing repetitive information from prospect lists, analyzing data or performing statistical
analysis on your use of the Platform or interactions on our websites, providing marketing assistance, processing
credit card payments, supplementing the information you provide us in order to provide you with better service,
developing and improving the product and services, and providing customer service or support. These service providers
are prohibited from using your Personal Data except for these purposes, and they are required to maintain the
confidentiality of your information. In all cases where we share your information in this way, we explicitly require
the third-party service providers to acknowledge and adhere to our privacy and data protection policies and
standards.

### b. Partners
We may share Personal Data with trusted CentralCI partners to provide our Services. We will also share Personal Data to
contact you based on your request to receive such communications, help us perform statistical analysis, provide sales
support, or provide customer support. Partners are required to maintain the confidentiality and security of your data.

We also partner with trusted third parties to provide you with co-marketing content that we think may be relevant to
you. When you engage with these co-marketing partners, we will provide a link to the co-marketing partner’s privacy
policy so you can learn more about how to opt out of the partner’s communications.

### c. Corporate Events
If we (or our assets) are acquired by another company, whether by merger, acquisition, bankruptcy or otherwise, that
company would receive all information gathered by CentralCI via providing the Services. In this event, you will be
notified via email and/or a prominent notice on our Website of any change in ownership, uses of your Personal Data, and
choices you may have regarding your Personal Data.

### d. Public Forums
Our websites may offer publicly accessible message boards, blogs, and community forums. Please keep in mind that if you
directly disclose any information through our public message boards, blogs, or forums (including profile information
associated with the Account you use to post the information) it may be read, collected, and used by any member of the
public who accesses these websites. Your posts and certain profile information may remain even after you terminate your
Account. We urge you to consider the sensitivity of any information you may disclose in this way. We will correct or
delete any information you have posted on the websites if you so request, as described in Section “Your Privacy Rights
and Choices” below. In some cases, we may not be able to remove your information, in which case we will let you know if
we are unable to and why.

### e. Compelled Disclosure
We reserve the right to use or disclose your Personal Data if required by law or if we reasonably believe that use or
disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or comply
with a law, court order, or legal process. If you use the Platform, Customer Data is considered Confidential Information
and you should review the Confidentiality terms in the Agreement for more information.

### f. Market Place Providers
When you purchase CentralCI Products or Services through a third-party market place, we provide Personal Data 
and usage data to the marketplace provider to facilitate order processing, delivery, customer support, and to 
meet legal obligations. This data includes your name, contact information, payment details, product usage 
and order history.

## 4. How We Transfer Personal Data Internationally
### a. International Transfers
This Privacy Policy will apply even if we transfer Personal Data to other countries. We have taken appropriate
safeguards to require that your Personal Data will remain protected wherever it is transferred. When we share
Personal Data of individuals in the European Economic Area (“EEA”), Switzerland or the United Kingdom (“UK”), we use
the EU-U.S. Data Privacy Framework, the UK Extension to the EU-U.S. DPF, and the Swiss-U.S. Data Privacy Framework (
collectively, the “Data Privacy Framework”). We also implement the Standard Contractual Clauses (approved by the
European Commission and Swiss authorities) and UK Addendum to the Standard Contractual Clauses (approved by the UK
authorities) where required, as well as additional safeguards where appropriate (such as commercial industry-standard
secure encryption methods to protect Customer data at rest and in transit, web application firewall protection, and
other appropriate contractual and organizational measures). Please see our Data Privacy Framework statement below for
more information.

### b. International transfers to third parties

Some of the third parties described in this Privacy Policy, which provide services to us under contract, are based in
other countries that may not have equivalent privacy and data protection laws to the country in which you reside. When
we share Personal Data of individuals in the EEA, Switzerland or UK with third parties, we use a variety of legal
mechanisms to safeguard the transfer, including the European Commission-approved Data Privacy Framework, the Standard
Contractual Clauses, as well as additional safeguards where appropriate. For transfers to or from the United Kingdom, we
make use of the UK Addendum. For transfers to or from Canada, we make use of the standard contractual clauses. With
respect to personal data received from or transferred to Canada, CentralCI is subject to the regulatory enforcement
powers of the Office of the Privacy Commissioner of Canada. Please contact us if you need more information about the
legal mechanisms we rely on to transfer personal data outside the EEA, Switzerland, Canada, and UK.

### c. Data Privacy Framework Notice
CentralCI complies with the EU-U.S. Data Privacy Framework (“EU-U.S. DPF”), the UK Extension to the EU-U.S. DPF, and the
Swiss-U.S. Data Privacy Framework (“Swiss-U.S. DPF”) as set forth by the U.S. Department of Commerce (collectively “the
Data Privacy Framework”).

CentralCI has certified to the U.S. Department of Commerce that it adheres to the EU-US DPF Principles with regard to
the processing of Personal Data received from the European Union, and the United Kingdom (and Gibraltar) and to the
Swiss-US DPF Principles with regard to the processing of Personal Data received from Switzerland. If there is any
conflict between this Privacy Policy and the DPF Principles, the DPF Principles shall govern. To learn more about the
Data Privacy Framework and to view our certification, please visit 
[https://www.dataprivacyframework.gov/](https://www.dataprivacyframework.gov/).

If you are located in the EU, UK, or Switzerland, you have the right to request access to the Personal Data that we hold
about you and request that we correct, amend, or delete your Personal Data if it is inaccurate or processed in violation
of the DPF Principles. We will give you an opportunity to opt out where Personal Data we control about you is to be
disclosed to an independent third party or used for a purpose that is materially different from those set out in this
Privacy Policy. If you would like to exercise any of your rights, please contact us via the details provided below.

In compliance with the DPF Principles, CentralCI commits to resolve DPF Principles-related complaints about our
collection and use of your Personal Data. We will investigate and attempt to resolve any DPF Principles-related
complaints within 45 days. EU, UK, and Swiss individuals with inquiries or complaints regarding our handling of Personal
Data received in reliance on the DPF Principles should first contact CentralCI via
[privacy@centralci.com](mailto:privacy@centralci.com).

If you have unresolved DPF-related complaints that we have not addressed satisfactorily, please contact the:

- EU DPA panel if you are based in the European Union.
- Swiss Federal Data Protection and Information Commissioner (FDPIC) if you are based in Switzerland.
- Information Commissioner’s Office if you are based in the United Kingdom.

Under certain conditions, more fully described on the DPF website, you may be entitled to invoke binding arbitration
when other dispute resolution procedures have been exhausted. The Federal Trade Commission has jurisdiction over
CentralCI’s compliance with the DPF Principles.

In the context of an onward transfer, CentralCI is responsible for the processing of Personal Data it receives under the
DPF Principles and subsequently transfers to a third party acting as an agent on our behalf. CentralCI shall remain
liable under the DPF Principles if our agent processes your Personal Data in a manner inconsistent with the DPF
Principles, unless CentralCI is not responsible for the event giving rise to the damage.

Please note that under certain circumstances, we may be required to disclose your Personal Data in response to lawful
requests by public authorities, including to meet national security or law enforcement requirements.

## 5. How We Store and Secure Personal Data
### a. Data Storage and Security

We use a variety of security technologies and procedures to help protect your Personal Data from unauthorized access,
use, or disclosure. We secure the Personal Data you provide on computer servers in a controlled, secure environment,
protected from unauthorized access, use, or disclosure. All Personal Data is protected using appropriate physical,
technical, and organizational measures. For more on Security at CentralCI, please
see https://centralci.com/docs/security/security-overview.

### b. Retention of Personal Data
How long we keep the information we collect about you depends on the type of information as well as how we collect and
store that information.
We retain Personal Data that you provide to us where we have an ongoing legitimate business need to do so (for example,
as needed to comply with our legal obligations, resolve disputes, and enforce our agreements).

When we have no ongoing legitimate business need to process your Personal Data, we securely delete the information or
anonymize it, or if deletion/anonymization is not possible, we will securely store your Personal Data and isolate it
from any further processing until deletion is possible. We will delete this information at an earlier date if you so
request, as described in the section “Privacy Rights and Choices” below.

If you have elected to receive marketing communications from us, we retain information about your marketing preferences
for a reasonable period of time, starting from the date you last expressed interest in our Customer Data, products, or
services, for example as when you last opened an email from us or ceased using your CentralCI Account.

Customer Data transferred to the Platform is retained according to the Agreement, Data Retention Policy, and any
applicable Data Processing agreement.

## 6. Your Privacy Rights and Choices
### a. Personal Data Requests  

You have the following data protection rights:

- Access, Correction, or Deletion. You can request access, correction, updates, or deletion of your Personal Data.
- Objection. You can object to our processing of your Personal Data, ask us to restrict processing of your Personal Data,
  or request portability of your Personal Data.
- Withdraw Consent. If we have collected and processed your Personal Data with your consent, then you can withdraw your
  consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted prior to
  your withdrawal, nor will it affect processing of your Personal Data conducted in reliance on lawful processing grounds
  other than consent.
- Complaint. You have the right to complain to a data protection authority about our collection and use of your Personal
  Data. Contact details for data protection authorities in the EEA are available 
  [here](https://edpb.europa.eu/about-edpb/about-edpb/members_en).
  
To exercise any of these rights, please contact us at [privacy@centralci.com](mailto:privacy@centralci.com) 
or raise a support ticket via the CentralCI Website. To raise a Data Subject Access Request, please use this form.

Please note that to protect personal information, we may verify your identity by a method appropriate to the type of
request you are making. Depending on where you reside, you may be entitled to empower an “authorized agent” to submit
requests on your behalf. We will require authorized agents to confirm their identity and authority, in accordance with
applicable laws. You are entitled to exercise the rights described above free from discrimination.

We will respond to your request to change, correct, or delete your data within a reasonable timeframe and notify you of
the action we have taken. In some instances, your rights may be limited, such as where fulfilling your request would
impair the rights of others, our ability to provide a service you have requested, or our ability to comply with our
legal obligations and enforce our legal rights.

### b. To Unsubscribe From Our Communications
You may unsubscribe from our marketing communications by clicking on the “unsubscribe” link located on the bottom of our
e-mails or by updating your communication preferences.

Customers cannot opt out of receiving transactional emails related to their Account.

### c. Choice
If Personal Data covered by this Privacy Policy is to be used for a new purpose that is materially different from that
for which the Personal Data was originally collected or subsequently authorized, or is to be disclosed to a non-agent
third party in a manner not specified in this Privacy Policy, CentralCI will provide you with an opportunity to choose
whether to have your Personal Data so used or disclosed. Requests to opt out of such uses or disclosures of Personal
Data should be sent to us as specified in the “Contact Us” section below.

## 7. California Privacy Rights
### a. Applicability

This section applies only to California consumers. For purposes of this section, “Personal Information” has the
meaning given in the California Consumer Privacy Act (“CCPA”). It describes how we collect, use, and share California
consumers’ Personal Information in our role as a business, and the rights applicable to such residents. The
California Consumer Privacy Act (“CCPA”) requires businesses to disclose whether they sell Personal Information.
CentralCI is a business and does not sell Personal Information. We may share Personal Information with authorized
service providers or business partners who have agreed to our contractual limitations as to their retention, use, and
disclosure of such Personal Information.

If you are unable to access this Privacy Policy due to a disability or any physical or mental impairment, please contact
us and we will arrange to supply you with the information you need in an alternative format that you can access.

### b. How We Collect, Use, and Share your Personal Information

We have collected the following statutory categories of Personal Information in the past twelve (12) months:

- Identifiers, such as name, email address, mailing address, and phone number. We collect this information directly from
  you or from third-party sources.
- Commercial information, such as Account records. We collect this information directly from you.
  Internet or network information, such as browsing and search history. We collect this information directly from your
  device.
- Geolocation data, such as IP address. We collect this information from your device.
- Financial information, such as Payment Information or financial Account numbers in the process of providing you with the
  Platform. We collect this information from you.
- Inferences.
- Other Personal Information, in instances when you interact with us online, by phone, or mail in the context of receiving
  help through our help desks or other support channels; participation in customer surveys or contests; or in providing
  the Services.

The business and commercial purposes for which we collect this information are described in Section 2 of this Privacy
Policy. The categories of third parties to whom we “disclose” this information for a business purpose are described in
Section 3 of this Privacy Policy. The period of time for which we retain this information is described in Section 5 of
this Privacy Policy.

### c. Your California Rights
You have certain rights regarding the Personal Information we collect or maintain about you. Please note these rights
are not absolute, and there may be cases when we decline your request as permitted by law.

The right of access means that you have the right to request that we disclose what Personal Information we have
collected, used, and disclosed about you in the past 12 months.

The right of deletion means that you have the right to request that we delete Personal Information collected or
maintained by us, subject to certain exceptions.

The right of correction means that you have the right to request that we correct any inaccurate personal information
that we maintain about you.

The right to non-discrimination means that you will not receive any discriminatory treatment when you exercise one of
your privacy rights.

CentralCI does not sell or share Personal Information to third parties (pursuant to California Civil Code §§
1798.100–1798.199, also known as the California Consumer Privacy Act of 2018).

### d. How to Exercise your California Rights
You can exercise your rights yourself, or you can alternatively designate an authorized agent to exercise these rights
on your behalf. Please note that to protect your Personal Information, we will verify your identity by a method
appropriate to the type of request you are making. We may also request that your authorized agent have written
permission from you to make requests on your behalf, and we may also need to verify your authorized agent’s identity to
protect your Personal Information.

Please use the contact details below, or see Section 7 “Your Privacy Rights and Choices” above, if you would like to:

- Access this policy in an alternative format;
- Exercise your rights;
- Learn more about your rights or our privacy practices; or
- Designate an authorized agent to make a request on your behalf.

## 8. Data Protection Officer

If you have any questions about this Privacy Policy or our privacy practices, or if you have a disability and need to
access this notice in a different format, contact us at [privacy@centralci.com](mailto:privacy@centralci.com) 
or raise a support ticket via the CentralCI Website.

## 9. Other Important Privacy Information
### a. We Never Sell Personal Data

We will never sell your Personal Data to any third party.

### b. Information About Children
The websites, product, Platform, and services are not intended for or targeted at children under 16 and we do not
knowingly or intentionally collect Personal Data about children under 16. If you believe that we have collected Personal
Data about a child under 16, please contact us so that we can delete the information. If you are a minor under the age
of 16, you are not permitted to have an Account on CentralCI. If we learn or have reason to suspect that you are a user
who is under the age of 16, we will unfortunately have to close your Account. Please see the Agreement for information
about Account termination.

### c. Third-Party Dispute Resolution
If you have an unresolved privacy or data use concern that we have not addressed satisfactorily, please contact the:

EU DPA panel if you are based in the European Union.

Swiss Federal Data Protection and Information Commissioner (FDPIC) if you are based in Switzerland.

Information Commissioner’s Office if you are based in the United Kingdom.

### d. Changes to this Privacy Policy
We reserve the right to modify this Privacy Policy at any time to reflect changes in our Services, any applicable laws,
or for other reasonable grounds. When changes are posted to this Policy, the “Last Updated” date at the top will be
revised.

If there are material changes to how CentralCI will use your Personal Data, we will notify you either by prominently
posting a notice of such changes prior to implementing the change or by directly sending you an email notification. We
encourage you to periodically review this Privacy Policy to remain informed of how CentralCI protects your information.

### e. Technical Preview
If you receive access to CentralCI products or services, features and technologies that are not yet generally available
on a free or paid basis, including, but not limited to, any products, services, or features labeled as an alpha, beta,
technical preview, or early access offering, or access and use available in regions that are not
generally available, including, but not limited to, any regions identified by CentralCI as “alpha”, “beta”, “preview”,
“pre-release”, or “experimental” (the “Technical Preview Products”), please be aware that this Privacy Policy may not
fully apply to these Technical Preview Products. Technical Preview Products are experimental and pre-release versions of
our offerings, and as such, we may not be able to commit to following all aspects of this Privacy Policy during the beta
testing phase. Technical Preview Products may undergo frequent changes and updates as we gather feedback and improve
their functionality.

While we strive to protect your privacy to the best of our ability, please understand that:

- **Limited Privacy Commitment**: During the testing and development of Technical Preview Products, our primary focus is on
  product improvement, functionality testing, and bug fixing. This may result in deviations from the usual data handling
  practices outlined in this Privacy Policy. If you have concerns about your privacy while using Beta Products, or if you
  wish to discontinue participation, please contact our support team or cease using the Beta Product.
- **Data Collection**: We may collect usage and performance data related to Technical Preview Products to understand how they
  are being used and to address technical issues. This data may not be subject to the same privacy protections as outlined
  in this Privacy Policy.
- **Notification**: We will make reasonable efforts to inform you of any specific privacy practices or changes that apply to
  Technical Preview Products but it is essential to exercise caution and discretion when using them. 
- **Feedback**: If you participate in a Technical Preview Product, we may request feedback and input from you. Your feedback
  can be valuable in shaping the final product, but it may involve sharing information that would not typically be
  collected under this Privacy Policy.

Once a Technical Preview Product transitions to a stable release, we will make every effort to ensure that it complies
with our standard Privacy Policy. We appreciate your understanding and participation in helping us improve our products
and services. Your privacy is important to us, even during the testing phase.

## Contact Us
If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data
or your choices and rights regarding such collection and use, please do not hesitate to contact us. Furthermore, if you
are interested in exercising any rights above (regardless of your geographical location), please contact us here:

[privacy@centralci.com](mailto:privacy@centralci.com)

Mailing Address: FAO: Data Protection Officer, CentralCI Inc, 1111B S Governors Ave STE 26209 Dover, DE, 19904 US

If you are located in the European Union, raise a support ticket via the CentralCI Website.