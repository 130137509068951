---
title: "Business Information"
description: "Business Information"
created: 2025-01-01
updated: 2025-01-01
footer_region: Compliance
rank: 2
show_toc: false
---

# CentralCI Inc.
Private Delaware Corporation

#### Incorporation Date
13 January 2025

#### Registered Address
1111B S Governors Ave STE 26209 Dover, DE, 19904 US

#### Address for correspondence
1111B S Governors Ave STE 26209 Dover, DE, 19904 US

#### EIN (tax ID)
TBD